import React from "react"
import { Container, Header } from "semantic-ui-react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image2 from "../components/image2"

const SharePage = () => (
  <Layout>
    <SEO title="Share" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Header as="h1" dividing>
        <Header.Content>
          Share &amp; Embed Misconceptions
          <Header.Subheader>
            A Curated Inventory of Programming Misconceptions
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        We believe misconceptions are valuable.
        Progmiscon.org enables and encourages sharing them.
      </p>
      <Header as="h2" dividing>
        Stable Names
      </Header>
      <p>
        Each misconception has a <strong>name</strong> (like <code>ObjectsMustBeNamed</code>).
        We strive to keep these names short but descriptive.
      </p>
      <p>
        We continuously grow and improve our inventory of misconceptions.
        This means that at some point we may want to rename a previously published misconception.
        We try to minimize such renamings,
        and when we do rename a misconception,
        we will keep the old name alive (and make it forward to the new name),
        to ensure that references to progmiscon misconceptions will never break.
      </p>
      <Header as="h2" dividing>
        Each Programming Language has its own Misconceptions
      </Header>
      <p>
        We organized the misconceptions by <strong>programming language</strong>.
        Our inventory currently includes misconceptions for
        <Link to='/misconceptions/Java'>Java</Link>, <Link to='/misconceptions/JavaScript'>JavaScript</Link>,
        and <Link to='/misconceptions/Python'>Python</Link>.
      </p>
      <p>
        If a misconception exists for multiple programming languages,
        then it has the same name for each language.
        For example:
      </p>
      <ul>
        <li><Link to='/misconceptions/Java/NoAtomicExpression'>Java / NoAtomicExpression</Link></li>
        <li><Link to='/misconceptions/JavaScript/NoAtomicExpression'>JavaScript / NoAtomicExpression</Link></li>
        <li><Link to='/misconceptions/Python/NoAtomicExpression'>Python / NoAtomicExpression</Link></li>
      </ul>
      <Header as="h2" dividing>
        Link to a Misconception
      </Header>
      <p>
        Each misconception is presented on its own page, 
        at a URL that looks as follows:
      </p>
      <p>
        <code>https://progmiscon.org/misconceptions/PL/MC</code>
      </p>
      <p>
        where <code>PL</code> stands for the programming language
        (e.g., <code>Java</code>, <code>JavaScript</code>, or <code>Python</code>),
        and <code>MC</code> stands for the name of a misconception
        (e.g., <code>ObjectsMustBeNamed</code>).
        This makes it easy to link to a specific misconception from anywhere.
      </p>
      <p>
        For example, ObjectsMustBeNamed for Java is available at:
      </p>
      <p>
        <a href='https://progmiscon.org/misconceptions/Java/ObjectsMustBeNamed'>https://progmiscon.org/misconceptions/Java/ObjectsMustBeNamed</a>
      </p>
      <Header as="h2" dividing>
        Post about a Misconception on Social Media 
      </Header>
      <p>
        If you post about a misconception on social media,
        include its link to get a nice card in your post:
      </p>
      <p style={{padding: '5px'}}>
        <Image2 style={{width: '50%', verticalAlign: 'text-top'}} className="ui image" src="TwitterCard.png" />
        <Image2 style={{width: '50%', verticalAlign: 'text-top'}} className="ui image" src="FacebookCard.png" />
      </p>
      <Header as="h2" dividing>
        Embed a Misconception (iframe)
      </Header>
      <p>
        You can include a misconception on your own web page in an iframe.
        Here is an example:
      </p>
      <iframe width="700" height="640" frameBorder="0" allowFullScreen src='https://progmiscon.org/iframe/misconceptions/Java/ObjectsMustBeNamed' title='Misconception at progmiscon.org'></iframe>
      <p>
        To get this compact presentation of the misconception
        (which omits the page header and footer, and the detailed discussion),
        use the URL for iframe content:
      </p>
      <p>
        <code>https://progmiscon.org/iframe/misconceptions/PL/MC</code>
      </p>
      <p>
        As with any iframe, you can pick its size.
        Our iframe content works with a relatively wide range of sizes.
        The iframe above is 700 by 640 pixels.
        Here is the HTML snippet we used:
      </p>
      <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'normal', display: 'block', overflow: 'auto'}}>
      {`<iframe
width="700"
height="640"
frameborder="0"
allowfullscreen
src='https://progmiscon.org/iframe/misconceptions/Java/ObjectsMustBeNamed'
title='Misconception at progmiscon.org'
></iframe>`}
      </pre>
      <Header as="h2" dividing>
        Retrieve the List of Misconceptions
      </Header>
      <p>
        We provide a so-called "static API",
        a JSON file you can download that contains
        the list of all misconceptions on this site.
      </p>
      <p>
        <a href='/json/data.json'>https://progmiscon.org/json/data.json</a>
      </p>
      <p>
        The JSON file has the following structure:
      </p>
      <pre>{`{
  "meta": {
    "source": "https://progmiscon.org",
    "publicationDate": "2020-12-29T14:53:47.391Z"
  },
  "pls": [
    "Java",
    "JavaScript",
    "Python"
  ],
  "concepts": [
    {
      "name": "AccessModifier"
    },
    {
      "name": "Allocation"
    },
...
  ],
  "misconceptions": [
    {
      "name": "PlusConcatenatesNumbers",
      "pl": "Python",
      "shortDescription": "The plus operator can concatenate strings and numbers",
      "concepts": [
        "Text",
        "Sequence",
        "Number",
        "Expression",
        "Operator"
      ],
      "status": "draft"
    },
    {
      "name": "NoSequenceRepetition",
      "pl": "Python",
      "shortDescription": "There is no operator that repeats sequences",
      "concepts": [
        "Text",
        "Sequence",
        "Number",
        "Repetition"
      ],
      "status": "draft"
    },
...
  ]
}
`}
      </pre>
      <p>
        The JSON file contains the list of programming languages for which we have misconceptions,
        the list of concepts that the misconceptions relate to,
        and the list of misconceptions.
      </p>
      <p>
        For each misconception it contains its name and programming language,
        its short description (a descriptive phrase without formatting),
        the list of concepts it is related to,
        and its status.
        The status is either <code>public</code> (fully documented)
        or <code>draft</code> (incompletely documented).
      </p>

    </Container>
  </Layout>
)

export default SharePage
